.iso-link {
  cursor: pointer;
}

.modal {
  display: none;
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

/* .modal-dialog {
  margin-top: 20%;
} */

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal-header {
  display: flex;
  align-items: center;
}

.btn-close {
  border: none;
  background-color: transparent;
  margin-left: 66%;
}

.policy-list {
  list-style: none;
  padding: 0;
}

.policy-list li {
  position: relative; 
  padding-left: 25px; 
}

.policy-list li::before {
  content: "✔"; 
  position: absolute;
  left: 0;
  color: #5ca9fb;
  font-size: 18px;
}


@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }


}

@media screen and (max-width: 600px) {

  #testimonials .center-vertically {
    display: inline;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #testimonials img {
    width: 100%;
    max-width: 15rem;
    max-height: 15rem;
    height: auto;
  }
}